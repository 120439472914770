/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/* ==========================================================================
   Variables
   ========================================================================== */

:root {
    --text-color: rgb(255, 255, 255);
    --background-color: rgb(23, 58, 66);
    --background-color-button: rgb(25,79,95);
    --background-color-button--pressed: rgb(16,41,48); /* Lighten on press */
    --button-color-disabled: rgb(55,126,146);
    --field-border-color: rgb(39,124,149);

    --color-01: rgb(202, 20, 27);
    --color-02: rgb(59, 110, 144);
    --color-03: rgb(16, 171, 43);
    --color-04: rgb(144, 34, 121);
    --color-05: rgb(235, 101, 1);
    --color-06: rgb(5,92,189);
    --color-07: rgb(111, 68, 34);
    --color-08: rgb(226, 55, 146);
    --color-09: rgb(0, 144, 136);
    --color-10: rgb(250, 54, 0);
    --color-11: rgb(71, 201, 255);
    --color-12: rgb(171, 29, 255);

    --row-indent: 80px;
    --axis-line-offset: 40px;
    --axis-line-width: 2px;
    --axis-padding: 1em;
    --axis-tag-size: 1.8em;

    /**
     * positions of the vertical line connecting sub axes
     */
    --axis-vertical-line-middle: calc(var(--row-indent) - var(--axis-line-offset));
    --axis-vertical-line-start: calc(var(--axis-vertical-line-middle) - var(--axis-line-width) * .5);
    --axis-vertical-line-end: calc(var(--axis-vertical-line-middle) + var(--axis-line-width) * .5);

    --axis-horizontal-line-middle: 2.1em;
    --axis-horizontal-line-start: calc(var(--axis-horizontal-line-middle) - var(--axis-line-width) * .5);
    --axis-horizontal-line-end: calc(var(--axis-horizontal-line-middle) + var(--axis-line-width) * .5);

    /**
     * Position of the tag bubble. Relative to the position of the vertical line
     */
    --axis-metadata-offset: calc(var(--axis-vertical-line-middle) + var(--axis-tag-size) * .5);

    --font-size: 15px;
    --line-height: 21px;

    --font-size--bigger: 1.44rem;
    --font-size--small: 0.833rem;

    --dash-size: .3em;
}




/* ==========================================================================
   Fonts
   ========================================================================== */

   @font-face {
    font-family: 'GothicA1';
    src: url(./fonts/GothicA1/GothicA1-Bold.ttf);
    font-weight: bold;
  }
  
  @font-face {
    font-family: 'GothicA1';
    src: url(./fonts/GothicA1/GothicA1-Medium.ttf);
    font-weight: 500;
  }
  
  @font-face {
    font-family: 'GothicA1';
    src: url(./fonts/GothicA1/GothicA1-Organon-ttfautohinted.ttf);
    font-weight: lighter;
  }
  
  @font-face {
    font-family: 'GothicA1';
    src: url(./fonts/GothicA1/GothicA1-Light.ttf);
    font-weight: light;
  }
  
  


/* ==========================================================================
   Global
   ========================================================================== */

html { font-size: 0.813em; }

body {
    font-family: 'GothicA1', sans-serif;
    line-height: var(--line-height);
    font-weight: 500;
    background-color: var(--background-color);
    color: var(--text-color);
}

a {
	color: inherit;
	text-decoration: none;
}

.link__faux {
  cursor: pointer;
}

a[href]:hover,
.link__faux:hover {
	color: var(--background-color);
	filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feComponentTransfer color-interpolation-filters="sRGB"><feFuncR type="linear" slope="2" /><feFuncG type="linear" slope="2" /><feFuncB type="linear" slope="2" /></feComponentTransfer></filter></svg>#filter');
	-webkit-filter: brightness(200%);
	filter: brightness(200%);
}

h2 {
  font-size: 125%;
  font-weight: bold;
  margin-top: 3lh;
}


/**
 * Score
 */
 .score__header {
	position: sticky;
	top: 0;
	left: 0;
	box-sizing: border-box;
	width: 100%;
	height: 5.0rem;
	display: flex;
	z-index: 2;
  align-items: flex-end;
	background: var(--background-color);
	padding: 2ch 0 0 0;
}

.score__title {
	font-size: 2rem;
	line-height: 1em;
	flex: 1;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

/**
 * Axis base structure
 */

.sublines {
  position: relative;
  list-style-type: none;
  margin: 0;
  padding: 0 0 0 var(--row-indent);
}

/**
  Use flex to make score lines only claim horizontal space they need.
 */
.score__container {
  display: flex;
  justify-content: flex-start;
}

/**
  Offset container for absolutely positioned arrows.
 */
.score__lines__wrapper {
  position: relative;
  padding-right: 200px; /* White space for the arrows to live */
  /* flex-grow: 1; */
}

/* Score axis
   ========================================================================== */

[data-is-mainline="true"] {
  left: calc(var(--row-indent) * -.5);
}

.score__container .axis-row--wrapper { --row-color: var(--color-01); --row-color-next: var(--color-02); --indent: 0px; --form-indent: 40px; }

.score__container .axis-row--wrapper .axis-row--wrapper { --row-color: var(--color-02); --row-color-next: var(--color-03); --indent: calc(1 * var(--row-indent)); --form-indent: calc(-1 * var(--row-indent)); }

.score__container .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper { --row-color: var(--color-03); --row-color-next: var(--color-04);  --indent: calc(2 * var(--row-indent));  --form-indent: calc(-2 * var(--row-indent)); }

.score__container .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper { --row-color: var(--color-04); --row-color-next: var(--color-05);  --indent: calc(3 * var(--row-indent));  --form-indent: calc(-3 * var(--row-indent)); }

.score__container .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper { --row-color: var(--color-05); --row-color-next: var(--color-06);  --indent: calc(4 * var(--row-indent));  --form-indent: calc(-4 * var(--row-indent)); }

.score__container .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper { --row-color: var(--color-06); --row-color-next: var(--color-07);  --indent: calc(5 * var(--row-indent));  --form-indent: calc(-5 * var(--row-indent)); }

.score__container .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper { --row-color: var(--color-07); --row-color-next: var(--color-08);  --indent: calc(6 * var(--row-indent));  --form-indent: calc(-6 * var(--row-indent)); }

.score__container .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper { --row-color: var(--color-08); --row-color-next: var(--color-09);  --indent: calc(7 * var(--row-indent));  --form-indent: calc(-7 * var(--row-indent)); }

.score__container .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper { --row-color: var(--color-09); --row-color-next: var(--color-10);  --indent: calc(8 * var(--row-indent));  --form-indent: calc(-8 * var(--row-indent)); }

.score__container .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper { --row-color: var(--color-10); --row-color-next: var(--color-11);  --indent: calc(9 * var(--row-indent));  --form-indent: calc(-9 * var(--row-indent)); }

.score__container .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper { --row-color: var(--color-11); --row-color-next: var(--color-12);  --indent: calc(10 * var(--row-indent));  --form-indent: calc(-10 * var(--row-indent)); }

.score__container .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper { --row-color: var(--color-12); --row-color-next: var(--color-01);  --indent: calc(11 * var(--row-indent));  --form-indent: calc(-11 * var(--row-indent)); }

.score__container .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper { --row-color: var(--color-01); --row-color-next: var(--color-02);  --indent: calc(12 * var(--row-indent));  --form-indent: calc(-12 * var(--row-indent)); }

.score__container .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper { --row-color: var(--color-02); --row-color-next: var(--color-03);  --indent: calc(13 * var(--row-indent));  --form-indent: calc(-13 * var(--row-indent)); }

.score__container .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper { --row-color: var(--color-03); --row-color-next: var(--color-04);  --indent: calc(14 * var(--row-indent));  --form-indent: calc(-14 * var(--row-indent)); }

.score__container .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper .axis-row--wrapper { --row-color: var(--color-04); --row-color-next: var(--color-05);  --indent: calc(15 * var(--row-indent));  --form-indent: calc(-15 * var(--row-indent)); }


.axis-row--wrapper {
  margin-left: 0em;
  max-width: initial;
  position: relative;
}

.axis-row--wrapper .axis-row--wrapper {
  /* max-width: calc(100% - var(--row-indent)); */
}

.line__body {
  position: relative;
  border-radius: 5px;
  margin-left: .5rem;
  padding: .25em .5em .25em .5em;
  background-color: var(--row-color);
  display: inline-block;
  vertical-align: middle;
  text-transform: lowercase;
}

.line__title {
	position: relative;
	font-size: 15.609px;
	font-size: 1.2rem;
	/* cursor: move; */
}

.line__actant,
.line__adresse,
.line__destination {
	position: relative;
	border-radius: 3px;
	padding: 0em .5em;
	background-color: var(--row-color);
	display: inline-block;
	vertical-align: middle;
	text-transform: lowercase;
  margin-left: .5rem;
}

.line__actant {
  margin-right: .5rem;
}

.line__terme,
.line__indications {
  margin-left: .5rem;
  position: relative;
}


.line__condition {
  margin-left: 1.93rem;
  position: relative;
}

.line__condition__spacer {
  width: calc(1.93rem + 8px);
  display: inline-block;
}

.line__condition::after,
.line__terme::after {
	content: " ";
	position: relative;
	display: inline-block;
	background-color: var(--row-color);
	height: 8px;
	width: 8px;
	border-radius: 50%;
  z-index: 1;
}

.line__indications .label,
.line__condition .label,
.line__terme .label {
	display: none;
	position: absolute;
  pointer-events: none;
	left: -4px;
  top: calc(-.6 * var(--font-size));
	z-index: 1;
	padding: 7px 8px 6px 16px;
	font-weight: 500;
	line-height: var(--font-size);
	/* -webkit-box-shadow: 2px 2px 0 var(--background-color);
	box-shadow: 2px 2px 0 var(--background-color); */
	color: var(--row-color);
	background-color: white;
}

/*
  Increase z-index of little dot and label on hover
  to make sure they're on top of others.
  Can happen when a line has a terme and condition.
  When the condition is hovered, the dot of the terme is
  put on top.
*/
.line__indications:hover::after,
.line__indications:hover .label,
.line__condition:hover::after,
.line__condition:hover .label,
.line__terme:hover::after,
.line__terme:hover .label {
  z-index: 2
}

.line__indications:hover .label,
.line__condition:hover .label,
.line__terme:hover .label {
	display: block;
}

/**
 * Still draw, but transparent on open rows, to avoid elements shifting / moving
 */
 details[data-has-sublines][open] > summary > .axis-row--background-wrapper > .line__body,
 details > summary > .axis-row--background-wrapper > .line__body {
  box-shadow: 1px 1px 0px transparent;
	border-right: 1px solid transparent;
	border-bottom: 1px solid transparent;
}


/**
 * Draw an indicator of depth on collapsed rows with sublines
 */
 details[data-has-sublines] > summary > .axis-row--background-wrapper > .line__body {
  box-shadow: 1px 1px 0px var(--row-color-next);
	border-right: 1px solid var(--background-color);
	border-bottom: 1px solid var(--background-color);
}

summary {
  list-style-type: none;
  position: relative;
  display: inline-block;
}

details > summary::-webkit-details-marker {
  display: none;
}

.axis-row--wrapper {
  padding-top: var(--axis-padding);
}

/**
 * Horizontal line in the header
 */
.axis-row--background-wrapper {
  display: inline-block;
  position: relative;
}

.axis-row--background-wrapper::before {
	content: " ";
	display: block;
	position: absolute;
	top: calc(50% - 1px);
	left: 0;
	right: 0;
	/* bottom: 13px; */
	border-top: 2px solid var(--row-color);
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

/**
 * Slightly shift it on the mainline
 */
[data-is-mainline="true"] > .axis-row > .axis-row--background-wrapper::before {
	left: calc(var(--row-indent) / 2);
}

.line__actions {
  display: inline-block;
  visibility: hidden;
}

summary:hover > .line__actions {
  visibility: visible;
}

.axis-row,
.axis-row--header,
.line__body,
.axis-row--tail {
  white-space: nowrap;
}


/**
 * Boucle
 */
 .boucle {
  margin-left: .5rem;
  border: 2px solid var(--row-color);
  background-color: var(--background-color);
  position: relative;
  height: 17.5px;
  font-size: var(--font-size--small);
  display: inline-block;
  border-radius: 8px;
  vertical-align: middle;
  padding-left: .5em;
  padding-right: .5em;
  min-width: 1em;
  text-align: center;
}

.boucle::before {
  content: "<";
  position: absolute;
  top: -11px;
  left: 5px;
  color: var(--row-color);
  font-weight: bold;
  font-size: 17px;
}

/**
 * Contingent dashes
 */
[data-contingent="true"] > .axis-row > .axis-row--background-wrapper > .line__actant,
[data-contingent="true"] > .axis-row > .axis-row--background-wrapper > .line__adresse,
[data-contingent="true"] > .axis-row > .axis-row--background-wrapper > .line__body,
[data-contingent="true"] > .axis-row > .axis-row--background-wrapper > .line__destination {
	background: repeating-linear-gradient(
      -45deg,
      var(--background-color) 0px,
      var(--background-color) 1px,
      transparent 1px, transparent 5px
    ),
    var(--row-color);
}

/**
 * Animation of modulated
 */

 @keyframes pulse {
	0% { opacity: 1; }
	100% { opacity: .5; }
}

[data-module="true"] > .axis-row > .axis-row--background-wrapper > .line__body > .line__title {
  animation: pulse 500ms infinite alternate;
}


/**
 * Vertical line in front of the axes
 */
.sublines::before {
	content: "";
	display: block;
	position: absolute;
	top: var(--axis-horizontal-line-end);
	bottom: 1px;
	border-left: 2px solid var(--row-color-next);
}

/**
 * Last axis, bottom of vertical line covered
 */
 .sublines > .axis-row--wrapper[data-is-last-line="true"]:last-child,
 .sublines > .drag-wrapper:last-child > .axis-row--wrapper[data-is-last-line="true"] {
  background:
    /* linear-gradient(to right, var(--background-color) var(--axis-vertical-line-start), transparent var(--axis-vertical-line-start)), */
    linear-gradient(to bottom,
      transparent var(--axis-horizontal-line-end),
      var(--background-color) var(--axis-horizontal-line-end));
}


[data-sublines="true"][data-collapsed="false"] > .axis-row > .sublines__metadata__wrapper {
  display: block;
}

/**
 * Makes sure the tag shifts up ?
 * FIX: better way of deriving top offset 
 */
.sublines__metadata__wrapper {
	position: absolute;
	top: 1.5em;
	bottom: .5em;
	left: calc(var(--row-indent) - 20px);
	width: 40px;
	/* display: none; */
}

/* When there are no sublines in the row. Don't show metadata. */
.sublines__metadata__wrapper:only-child {
	visibility: hidden;
}

/**
 * 
 */
.sublines__metadata {
  position: sticky;
  top: 5.0rem;
  z-index: 1;
  text-align: center;
}

/**
 * Tag
 */
.tag[data-tag] {
  display: block;
  margin: 0 auto;
  margin-bottom: 4px;
  background: var(--row-color-next);
  width: var(--axis-tag-size);
  height: calc(var(--axis-tag-size) - 4px);
  padding-top: 4px;
  border-radius: calc(var(--axis-tag-size) / 2);
  text-align: center;
  z-index: 1;
  font-weight: lighter;
  position: relative;
}

.tag[data-tag=">"]:before { content: "\f8b3"; }
.tag[data-tag="|"]:before { content: "\f8b4"; }
.tag[data-tag="||"]:before { content: "\f8b5"; }
.tag[data-tag="≥"]:before { content: "\f8b6"; }
.tag[data-tag="//"]:before { content: "\f8b7"; }


/**
 * Transition
 */
.transition {
  position: relative;
}

.transition::before {
  content: attr(data-transition);
}

.transition[data-transition="chained"]::before {
  content: "";
}


/**
 * Alternative
 */ 
.alternative {
  display: block;
  margin: 0 auto;
  font-size: var(--font-size--small);
  /* line-height: 1.5; */
  border: 2px solid var(--row-color-next);
  background-color: var(--background-color);
  font-weight: bold;
  padding: 0em .8em 0 .8em;
  color: white;
  border-radius: .8em/50%;
  z-index: 1;
  position: relative;
  top: 11px;
  white-space: nowrap;
}

/* Make lines dotted on alternative */
[data-alternative] > .sublines::before {
	border-left-style: dotted;
}

[data-alternative] > .sublines > .axis-row--wrapper > .axis-row > .axis-row--background-wrapper::before {
  border-top-style: dotted;
}

/**
 * Checkbox on lines in player
 */
 .axis-row--selectable--input {
    position: relative;
    width: 2ch;
    height: 2ch;
    margin-left: 2ch;
    margin-right: -4ch;
    z-index: 1;
    vertical-align: middle;
 }

 
/* Forms
   ========================================================================== */

   fieldset {
    margin-bottom: 2em;
    padding-left: 1em;
  }
  
  fieldset legend { margin-left: -1em; }
  
  textarea {
    width: 20em;
    height: 5em;
    vertical-align: top;
  }
  
  button, input, select, option, textarea {
    font-family: 'GothicA1', sans-serif;
    /* font-size: inherit; */
    /* line-height: inherit; */
    font-weight: 500;
  }
  
  button, submit, reset {
    color: var(--text-color);
    border: none;
    padding: .3em 1em .2em 1em;
    background: transparent;
    margin-top: .15em;
  }
  
  select:disabled, input:disabled {
    color: var(--button-color-disabled) !important;
    border-color: var(--button-color-disabled) !important;
    opacity: .75;
  }
  
  button:hover, submit:hover, reset:hover { background: var(--background-color-button); }
  
  button:active, submit:active, reset:active {
    outline: none;
    box-shadow: inset 0px 0px 2px rgb(4,13,16);
    background: var(--background-color-button--pressed);
  }
  
  button:disabled {
    color: var(--button-color-disabled);
  }
  
  button:disabled:hover {
    background: transparent;
  }
  
  label {
    display: block;
    margin-bottom: .5em;
  }
  
  .fieldgroup { display: block; }
  
  label.inline,
  section.inline { display: inline-block; }
  
  label .label-text {
    display: inline-block;
    width: 9em;
    margin-right: .25em;
    text-align: left;
  }
  
  input[type="text"] { /* width: 20em; */ }
  
  input.small-number { width: 4em; }
  
  section.hidden { display: none; }
/*   
  [name="extra-fields"] .fields { display: none; }
  
  [name="extra-fields"].expanded .fields { display: block; }
  
  [name="extra-fields"] legend:before { content: "▶"; }
  
  [name="extra-fields"].expanded legend:before { content: "▼"; }
   */
.line__editor {
  position: fixed;
  top: 10vh;
  left: 10vw;
  z-index: 8;
  background: var(--background-color);
  padding: 2em;
  border: 2px solid white;
  max-height: calc(80vh - 4em);
  overflow: auto;
}

#permissions input, 
.score-form input,
.score-form select,
.score-form textarea,
.line__editor input,
.line__editor select,
.line__editor textarea {
	background: var(--background-color);
	border: 1px solid white;
	color: var(--text-color);
	padding: .4em .5em .3em .5em;
}

fieldset {
  border: none;
}


label .label-text, .js-help {
  display: inline-block;
  width: 9em;
  margin-right: .25em;
  text-align: left;
}

dialog {
  z-index: 9;
  background-color: var(--background-color);
  border-color: white;
}

.attachment--link {
  cursor: zoom-in;
}

.attachment-preview {
	display: grid;
	grid-template-rows: 1fr min-content;
}

.attachment-preview--attachment {
  margin-bottom: var(--line-height);
}

.attachment-preview--attachment[data-type="image"] {
  display: flex;
  align-items: center;
  justify-content: center;
}

.attachment-preview--attachment[data-type="image"] img {
  max-width: 80vw;
  max-height: 80vh;
  width: auto;
  height: auto;
}

.attachment-preview--attachment[data-type="audio"] audio {
  width: 80vw;
}

.attachment-preview--attachment[data-type="video"] video {
  width: 80vw;
  max-height: 80vh;
}

.attachment-preview--attachment[data-type="pdf"] object {
  width: 80vw;
  height: 80vh;
}



/* Nesting level ruler
   ========================================================================== */

   .score__slider {
    position: sticky;
    border-top: .45rem solid var(--background-color);
    top: 5.0rem;
    margin-bottom: 2rem;
    white-space: nowrap;
    z-index: 1;
    line-height: 0;
  }
  
  .slider__level {
    display: inline-block;
    width: var(--row-indent);
    height: calc(var(--axis-tag-size) / 2);
    position: relative;
  }
  
  .slider__level:nth-child(1) {
    background-color: var(--color-01);
    color: var(--color-01);
  }
  
  .slider__level:nth-child(2) {
    background-color: var(--color-02);
    color: var(--color-02);
  }
  
  .slider__level:nth-child(3) {
    background-color: var(--color-03);
    color: var(--color-03);
  }
  
  .slider__level:nth-child(4) {
    background-color: var(--color-04);
    color: var(--color-04);
  }
  
  .slider__level:nth-child(5) {
    background-color: var(--color-05);
    color: var(--color-05);
  }
  
  .slider__level:nth-child(6) {
    background-color: var(--color-06);
    color: var(--color-06);
  }
  
  .slider__level:nth-child(7) {
    background-color: var(--color-07);
    color: var(--color-07);
  }
  
  .slider__level:nth-child(8) {
    background-color: var(--color-08);
    color: var(--color-08);
  }
  
  .slider__level:nth-child(9) {
    background-color: var(--color-09);
    color: var(--color-09);
  }
  
  .slider__level:nth-child(10) {
    background-color: var(--color-10);
    color: var(--color-10);
  }
  
  .slider__level:nth-child(11) {
    background-color: var(--color-11);
    color: var(--color-11);
  }
  
  .slider__level:nth-child(12) {
    background-color: var(--color-12);
    color: var(--color-12);
  }
  
  .slider__level:nth-child(13) {
    background-color: var(--color-01);
    color: var(--color-01);
  }
  
  .slider__level:nth-child(14) {
    background-color: var(--color-02);
    color: var(--color-02);
  }
  
  .slider__level:nth-child(15) {
    background-color: var(--color-03);
    color: var(--color-03);
  }
  
  .slider__level:nth-child(16) {
    background-color: var(--color-04);
    color: var(--color-04);
  }
  
  .slider__label { display: none; }


.content {
  padding: 30px;
}

/* Score metadata
   ========================================================================== */

.panel--score-meta .content {
  /* font-size: 85%; */
  padding: 90px 15px 30px 40px;
  width: 280px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 90px);
}

.panel--score-meta {
  position: relative;
  flex: 0 0 335px;
  transition: flex-basis .2s;
  overflow-x: hidden;
  background: var(--background-color);
  z-index: 3;
}

.panel--score-meta.is-collapsed {
  flex: 0 0 30px;
}

.panel--score-meta.is-collapsed .content {
  display: none;
}

.panel__toggle__alt {
  box-sizing: border-box;
  line-height: 30px;
  position: absolute;
  transform: rotate(-90deg);
  transform-origin: bottom right;
  white-space: nowrap;
  right: calc(100% - 30px);
  top: 0;
}

.panel__toggle__alt:hover {
  color:    #173a42;
  filter: brightness(200%);
  cursor: pointer;
}

.panel--score-meta .score-edit {
  position: absolute;
  right: 30px;
  top: calc(30px + 0em);
}

.read-only .score-edit,
[data-read-only="true"] .line__actions {
  display: none;
}

.panel--score-meta [data-name="attachment"] .label-text {
  width: 0;
}
  

/* inline metadata */
/* publié le */
/* modifié le */
.score-meta__term {
  display: inline-block;
  margin-top: .5em;
  font-weight: bold;
  break-after: avoid;
}

.score-meta__term::after {
  content: " : ";
}

.score-meta__term--tag {
  display: none;
}
.score-meta__def {
  display: inline;
  margin: 0;
  margin-left: 0em;
  padding-left: .5ch;
  break-before: avoid;
}


.score-meta__def:after{
  display: block;
  content: '';
}

.score-meta__term--inline { font-weight: inherit; }

.score-meta__term--inline::after { content: " "; }

.tags__item { 
  display: inline; 
  margin-right: 1em; 
}

.tags,
#permissions,
.score-meta {
  margin-bottom: 1.4em;
}

.tag[data-tag] {
  display: block;
  margin: 0 auto;
  margin-bottom: 4px;
  background: var(--row-color-next);
  width: var(--axis-tag-size);
  height: calc(var(--axis-tag-size) - 4px);
  padding-top: 4px;
  border-radius: calc(var(--axis-tag-size) / 2);
  text-align: center;
  z-index: 1;
  font-weight: lighter;
  position: relative;
}


.tag[data-tag=""]:before,
.tag[data-tag=">"]:before { content: "\f8b3"; }

.tag[data-tag="|"]:before { content: "\f8b4"; }
.tag[data-tag="||"]:before { content: "\f8b5"; }
.tag[data-tag="≥"]:before { content: "\f8b6"; }
.tag[data-tag="//"]:before { content: "\f8b7"; }

[data-selectable="true"][data-selected="false"] .axis-row--background-wrapper {
  opacity: .75;
}

.boucle input {
	color: inherit;
	background: var(--background-color);
	border: none;
}

.connector[data-type="pointTo--FEEDS"] .connector--from::before {
  font-size: 110%;
	content: "feeds";
	background: var(--background-color);
	position: absolute;
	top: calc(-.5 * var(--line-height));
	padding-right: 2ch;
}

.languageSelector {
	position: absolute;
	right: 2ch;
	top: 2ch;
}

ul.score-meta--autocomplete {
	position: absolute;
  width: 100%;
	background: #FFF;
	margin: 0;
	padding: 0;
	overflow: auto;
	max-height: 300px;
	list-style-type: none;
}

ul.score-meta--autocomplete li {
	list-style: none;
	margin: 0;
	padding: 2px 5px;
	white-space: nowrap;
	overflow: hidden;
	color: var(--background-color);
	cursor: default;
}

ul.score-meta--autocomplete li:hover {
	cursor: pointer;
	background-color: #d3d3d3;
}

.score-meta__shared-with {
  list-style: none;
  margin: 0;
  padding: 0;
}


/* Main menu (sidebar)
   ========================================================================== */

.main-header { width: 260px; }

.main-header.is-collapsed { margin-left: -260px; }

.main-header__content {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    box-sizing: border-box;
}

.main-header__create { flex-grow: 1; }

.main-header__logo {  margin-bottom: calc(var(--line-height) * 4); }

.logo__name {
  font-size: 3rem;
}

.scores__item {
	margin-top: 10.5px;
}

.item__title {
	font-weight: bold;
}

.item__meta {
	font-size: var(--font-size--small);
}


/***
 * Collapsable panel
 * Details tags with an absolutely positioned summary inside
 * which has a vertical writing mode.
 *
 * The panel gets its minimal width from the padding to make space
 * for the absolutely positioned summary.
 */

.panel__collapsable {
  /* 
    Make sure summary is positioned against geometry of details tag.
    Make space with padding
  */
  position: relative;
  padding-left: 2lh;
}

.panel__collapsable[open] {
  width: 50ch;
}

.panel__collapsable summary {
  z-index: 1;
  writing-mode: vertical-lr;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 2lh;
  display: flex;
  align-items: center;
  padding-top: 4ch;
  cursor: pointer;
}

.panel__collapsable summary:hover {
	color: var(--background-color);
  -webkit-filter: brightness(200%);
	filter: brightness(200%);
}

.panel__collapsable[data-align="right"] {
  padding-left: 0;
  padding-right: 2lh;
}

.panel__collapsable[data-align="right"] summary {
  right: 0;
  left: initial;
}

.panel__collapsable .content {
  padding: 3ch 2ch 2ch 1ch;
}

#panel--help iframe {
  border: none;
  width: 100%;
  height: 100%;
}

.panel__collapsable .main-header__content {
  padding: 5ch 3ch 2ch 5ch;
}

#form__login {
  margin-bottom: 1lh;
}