body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


details {
  margin-left: 2em;
  max-width: 80%;
}

details details {
  max-width: calc(100% - 2em);
}

[data-dnd-dragging="true"] {
  opacity: .25;
}

/* [data-dnd-is-over="true"] {
  background-color: bisque;
  outline: 2px dotted red;
} */